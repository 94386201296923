const Utils = {
  options: {
    moneyFormat: {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    },
    percentageFormat: {
      minimumFractionDigits: 2,
      style: "percent",
    },
  },
  format: {
    currency: value => parseFloat(value).toLocaleString("pt-BR", Utils.options.moneyFormat),
    percentage: value => parseFloat(value).toLocaleString("pt-BR", Utils.options.percentageFormat),
  },
};

export default Utils;
