import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Utils from "lib/utils";
import dashboardDomain from "../../domain/dashboard";

const Header = () => {
  const userInfo = useSelector(state => state.auth.extra);

  const information = valor => {
    if (valor === Infinity) {
      return <h5 className="text-muted mr-2 mt-3">{"Dados insuficientes para cálculos"}</h5>;
    }

    let resp;
    if (valor > 0) {
      resp = (
        <span className="text-success mr-2">
          <i className="fas fa-arrow-up" /> {Utils.format.percentage(valor / 100)}
        </span>
      );
    } else {
      resp = (
        <span className="text-danger mr-2">
          <i className="fas fa-arrow-down" /> {Utils.format.percentage(valor / 100)}
        </span>
      );
    }
    return (
      <p className="mt-3 mb-0 text-muted text-sm">
        {resp} <span className="text-nowrap">Desde a última hora</span>
      </p>
    );
  };

  const {
    billing,
    quantityItemsSold,
    salesAmount,
    infBilling,
    infSales,
    infAverageTicket,
    infItemsSales,
  } = dashboardDomain.getDataHeader();

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 pt-md-8"
        style={{
          minHeight: "250px",
          backgroundImage: `url(${userInfo && userInfo.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <span
          className={`mask bg-gradient-info ${
            userInfo && userInfo.backgroundImage ? "opacity-5" : ""
          }`}
        />
        <Container fluid>
          <div className="header-body">
            <Row className="d-flex align-items-stretch">
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Faturamento
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {Utils.format.currency(billing)}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    {information(infBilling)}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Vendas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{salesAmount}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                    {information(infSales)}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          Ticket Médio
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {billing && salesAmount
                            ? Utils.format.currency(billing / salesAmount)
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {information(infAverageTicket)}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                          (Itens/Vendas)
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {quantityItemsSold && salesAmount
                            ? (quantityItemsSold / salesAmount).toFixed(2)
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    {information(infItemsSales)}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
