import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";

const UserHeader = () => {
  const auth = useSelector(state => state.auth);

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage: `url(${auth.extra && auth.extra.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center top"
        }}
      >
        <span className="mask bg-gradient-default opacity-8" />
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">
                Olá {auth.user.displayName ? auth.user.displayName : auth.user.email}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
