import React from "react";
import { Container, Row } from "reactstrap";
import moment from "moment";

const AuthFooter = () => {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <div className="copyright text-center text-muted">
              Developed with ♥ by{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://zerosoft.com.br/?ref=zero-bazar"
                rel="noopener noreferrer"
                target="_blank"
              >
                ZeroSoft
              </a>
            </div>
          </Row>
          <Row className="align-items-center justify-content-center">
            <div className="copyright text-center text-muted">
              {`© ${moment().format("YYYY")} `}
              <a
                className="font-weight-bold ml-1"
                href="https://www.creative-tim.com?ref=adr-auth-footer"
                rel="noopener noreferrer"
                target="_blank"
              >
                Creative Tim
              </a>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default AuthFooter;
