const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem('@0Soft:user')) || null,
  extra: JSON.parse(localStorage.getItem('@0Soft:user:extra')) || null,
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "SET_USER":
      return { ...state, user: action.payload.user };
    case "SET_EXTRA_USER":
      return { ...state, extra: action.payload.extra };
    default:
      return state;
  }
}