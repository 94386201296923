import { swal } from "react-redux-sweetalert";
import moment from "moment";
import { getProduct } from "../products/actions";
import { addSale } from "../sales/actions";
import { db } from "services/firebase";

export const fetchCarts = () => {
  return async dispatch => {
    db.collection("carts")
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        dispatch({
          type: "FETCH_CARTS",
          payload: { data },
        });
      });
  };
};

export const incItemCart = barcode => {
  return async dispatch => {
    let cart = (await JSON.parse(localStorage.getItem("@0Soft:cart"))) || [];
    let item = cart.filter(c => c.barcode === barcode);
    item[0].quantity += 1;
    cart = [...cart.filter(c => c.barcode !== barcode), ...item];
    await localStorage.setItem("@0Soft:cart", JSON.stringify(cart));
    dispatch({ type: "ADD_ITEM_CART", payload: { item: item[0] } });
  };
};

export const decItemCart = barcode => {
  return async dispatch => {
    let cart = (await JSON.parse(localStorage.getItem("@0Soft:cart"))) || [];
    let item = cart.filter(c => c.barcode === barcode);

    if (item[0].quantity > 1) {
      item[0].quantity -= 1;

      cart = [...cart.filter(c => c.barcode !== barcode), ...item];
      await localStorage.setItem("@0Soft:cart", JSON.stringify(cart));
      dispatch({ type: "ADD_ITEM_CART", payload: { item: item[0] } });
    } else {
      dispatch(removeItemCart(barcode));
    }
  };
};

export const addItemCart = barcode => {
  return async dispatch => {
    const product = await dispatch(getProduct(barcode));

    if (product) {
      let cart = (await JSON.parse(localStorage.getItem("@0Soft:cart"))) || [];
      let item = cart.filter(c => c.barcode === barcode);
      if (item.length) {
        item[0].quantity += 1;
        item[0].updateAt = moment().format();
      } else {
        item = [{ barcode, ...product, quantity: 1, updateAt: moment().format() }];
      }
      cart = [...cart.filter(c => c.barcode !== barcode), ...item];
      await localStorage.setItem("@0Soft:cart", JSON.stringify(cart));
      dispatch({ type: "ADD_ITEM_CART", payload: { item: item[0] } });
    } else {
      dispatch(
        swal(
          ":( Nenhum item encontrado",
          "Por favor verifique o código de barras digitado",
          "error"
        )
      );
    }
  };
};

export const clearCart = () => {
  return async dispatch => {
    await dispatch(
      swal({
        title: "Tem certeza?",
        text: "Deseja cancelar essa venda?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5E72E4",
        cancelButtonText: "Sim, cancelar",
        confirmButtonText: "Continuar venda",
        onCancel: async () => {
          localStorage.removeItem("@0Soft:cart");
          dispatch({ type: "CLEAR_CART" });
        },
      })
    );
  };
};

export const removeItemCart = barcode => {
  return async dispatch => {
    await dispatch(
      swal({
        title: "Tem certeza?",
        text: "Deseja remover o item do carrinho?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5E72E4",
        cancelButtonText: "Sim, remover",
        confirmButtonText: "Manter",
        onCancel: async () => {
          let cart = (await JSON.parse(localStorage.getItem("@0Soft:cart"))) || [];
          cart = cart.filter(c => c.barcode !== barcode);
          await localStorage.setItem("@0Soft:cart", JSON.stringify(cart));
          dispatch({ type: "REMOVE_ITEM_CART", payload: { barcode: barcode } });
        },
      })
    );
  };
};

export const endCart = (payment, exchange) => {
  return async dispatch => {
    dispatch({ type: "END_CART_LOAD" });
    const cart = (await JSON.parse(localStorage.getItem("@0Soft:cart"))) || [];
    let amount = 0;
    let totalItems = 0;

    cart.forEach(c => {
      amount += c.price * c.quantity;
      totalItems += c.quantity;
    });

    const sale = { amount, totalItems, payment, items: cart, createdAt: moment().format() };

    if (exchange) {
      sale.exchange = exchange;
    }

    try {
      await dispatch(addSale(sale));

      localStorage.removeItem("@0Soft:cart");
      dispatch({ type: "CLEAR_CART" });
      dispatch(swal(":) opa", "Venda finalizada com sucesso", "success"));
    } catch (error) {
      console.log(error);
      dispatch(swal(":( Erro ao finalizar venda", "Estamos verificando...", "error"));
    }
  };
};
