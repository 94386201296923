import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRouter = ({ component: Component, location, ...rest }) => {
  const auth = useSelector(state => state.auth);

  return (
    <Route
      {...rest}
      render={props => {
        return (auth.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        ));
      }}
    />
  );
};

export default PrivateRouter;