const INITIAL_STATE = {
  loading: false,
  data: [],
  current: JSON.parse(localStorage.getItem("@0Soft:cart")) || [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_CARTS":
      return { ...state, data: action.payload.data };
    case "CLEAR_CART":
      return { ...state, current: [], loading: false };
    case "ADD_ITEM_CART":
      const items = [
        ...state.current.filter(c => c.barcode !== action.payload.item.barcode),
        ...[action.payload.item],
      ];

      return { ...state, current: items };
    case "REMOVE_ITEM_CART":
      return {
        ...state,
        current: [...state.current.filter(c => c.barcode !== action.payload.barcode)],
      };
    case "END_CART_LOAD":
      return { ...state, loading: true };
    default:
      return state;
  }
};
