import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Table,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const paginationBase = {
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  pageNumbers: [],
  perPage: 0,
  visibleNumbers: 5,
  showPagination: true,
};

const interactionBase = {
  sortColumn: "",
  sortInverse: 0,
};

const Datatable = ({ columns, data, options, title, className, noDataFound }) => {
  const [rows, setRows] = useState([]);
  const [list, setList] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [interaction, setInteraction] = useState(interactionBase);
  const [pagination, setPagination] = useState({
    ...paginationBase,
    ...options,
  });

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (columns.length) {
      const column = columns.filter(c => c.defaultSort)[0];
      setInteraction({
        ...interactionBase,
        sortColumn: column.name || "",
        sortInverse: column.sortInverse ? -1 : 0 || 0,
      });
    }
  }, [columns]);

  useEffect(() => {
    const indexColumn = columns.map(c => c.name).indexOf(interaction.sortColumn);
    let dataTmp = data;
    if (indexColumn >= 0) {
      dataTmp = data.sort((a, b) => {
        if (interaction.sortInverse === 0) {
          if (a[indexColumn] > b[indexColumn]) {
            return 1;
          }
          return -1;
        }

        if (b[indexColumn] > a[indexColumn]) {
          return 1;
        }
        return -1;
      });
    }

    setRows(dataTmp);
  }, [columns, data, interaction]);

  useEffect(() => {
    const chunk = _.chunk(rows, pagination.perPage);

    setList(chunk[0] || []);
    setPaginated(chunk);
  }, [pagination.perPage, rows, interaction]);

  useEffect(() => {
    const paginationTemp = {
      totalItems: rows.length,
      perPage: options.perPage,
      visibleNumbers: options.visibleNumbers || paginationBase.visibleNumbers,
      showPagination: options.hasOwnProperty("showPagination")
        ? options.showPagination
        : paginationBase.showPagination,
      currentPage: pagination.currentPage,
    };
    paginationTemp.totalPages = Math.ceil(rows.length / options.perPage);
    paginationTemp.pageNumbers = _.range(1, paginationTemp.totalPages + 1);
    setPagination(paginationTemp);
  }, [rows, options, pagination.currentPage]);

  useEffect(() => {
    setList(paginated[pagination.currentPage - 1] || []);
  }, [paginated, pagination.currentPage]);

  const page = useCallback(
    (e, page) => {
      e.preventDefault();
      setPagination({ ...pagination, currentPage: page });
      setList(paginated[page - 1]);
    },
    [paginated, pagination]
  );

  const next = useCallback(
    e => {
      e.preventDefault();

      if (pagination.currentPage < pagination.totalPages) {
        setPagination({ ...pagination, currentPage: pagination.currentPage + 1 });
      }
    },
    [pagination]
  );

  const previous = useCallback(
    e => {
      e.preventDefault();

      if (pagination.currentPage > 1) {
        setPagination({ ...pagination, currentPage: pagination.currentPage - 1 });
      }
    },
    [pagination]
  );

  const doSort = useCallback(
    (e, column) => {
      e.preventDefault();

      let { sortInverse, sortColumn } = interaction;

      if (sortColumn === "" || sortColumn === column) {
        sortInverse = sortInverse === 0 ? -1 : 0;
      } else {
        sortInverse = 0;
      }

      setInteraction({ sortInverse, sortColumn: column });
    },
    [interaction]
  );

  return (
    <Card className={className}>
      {title && (
        <CardHeader className="border-0">
          {typeof title === "string" ? <h3 className="mb-0">{title}</h3> : title}
        </CardHeader>
      )}
      <div className="d-flex justify-content-between flex-column h-100">
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              {columns
                .filter(c => !c.hidden)
                .map(c => {
                  return (
                    <th key={c.name} className={c.className} scope="col" width={c.width}>
                      {c.sort ? (
                        <>
                          <i
                            className={`fa ${
                              interaction.sortColumn === c.name
                                ? interaction.sortInverse === 0
                                  ? "fa-sort-amount-up"
                                  : "fa-sort-amount-down"
                                : "fa-sort"
                            }`}
                          />
                          <a href="#pablo" onClick={e => doSort(e, c.name)}>
                            {c.name}
                          </a>
                        </>
                      ) : (
                        c.name
                      )}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {list.length ? (
              list.map((d, index) => {
                return (
                  <tr key={index}>
                    {columns.map((c, i) => {
                      if (!c.hidden) {
                        if (c.customRender) {
                          return (
                            <td key={i} className={`text-${c.align ? c.align : "left"}`}>
                              {c.customRender(d[i])}
                            </td>
                          );
                        }

                        if (i === 0) {
                          return (
                            <th
                              scope="row"
                              className={`text-${c.align ? c.align : "left"}`}
                              key={i}
                            >
                              {d[i]}
                            </th>
                          );
                        }

                        return (
                          <td className={`text-${c.align ? c.align : "left"}`} key={i}>
                            {d[i]}
                          </td>
                        );
                      }

                      return null;
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} align="center">
                  {noDataFound}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {pagination.showPagination && (
          <CardFooter className="py-4 align-self-center w-100">
            {pagination.pageNumbers.length ? (
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-center mb-0"
                  listClassName="justify-content-center mb-0"
                >
                  <PaginationItem className={pagination.currentPage === 1 ? "disabled" : ""}>
                    <PaginationLink href="#pablo" onClick={previous} tabIndex="-1">
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {pagination.pageNumbers.map(p => {
                    if (Math.abs(p - pagination.currentPage) <= pagination.visibleNumbers) {
                      return (
                        <PaginationItem
                          key={p}
                          className={p === pagination.currentPage ? "active" : ""}
                        >
                          <PaginationLink href="#pablo" onClick={e => page(e, p)}>
                            {p}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }

                    return null;
                  })}
                  <PaginationItem
                    className={pagination.currentPage === pagination.totalPages ? "disabled" : ""}
                  >
                    <PaginationLink href="#pablo" onClick={next}>
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            ) : null}
          </CardFooter>
        )}
      </div>
    </Card>
  );
};

Datatable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      defaultSort: PropTypes.bool,
      customRender: PropTypes.func,
      hidden: PropTypes.bool,
      sortInverse: PropTypes.bool,
      width: PropTypes.number,
      align: PropTypes.string,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.array),
  options: PropTypes.shape({
    perPage: PropTypes.number,
    visibleNumbers: PropTypes.number,
    showPagination: PropTypes.bool,
  }),
  className: PropTypes.string,
  title: PropTypes.node,
  noDataFound: PropTypes.string,
};

Datatable.defaultProps = {
  columns: [],
  data: [],
  options: {
    perPage: 10,
    visibleNumbers: 5,
    showPagination: true,
  },
  className: "",
  noDataFound: "Nenhum dado encontrado",
};

export default Datatable;
