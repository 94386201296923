import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ReduxSweetAlert from "react-redux-sweetalert";
import moment from "moment";
import "moment/locale/pt-br";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "sweetalert/dist/sweetalert.css";

import store from "./store";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import CheckoutLayout from "layouts/Checkout.jsx";
import { PrivateRouter, PublicRouter } from "components/Router";

moment.locale("pt-br");
const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/admin"
            render={props => <PrivateRouter component={AdminLayout} {...props} />}
          />
          <Route
            path="/auth"
            render={props => <PublicRouter component={AuthLayout} {...props} />}
          />
          <Route path="/checkout" render={props => <CheckoutLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      </BrowserRouter>
      <ReduxSweetAlert store={store} />
    </Provider>
  );
};

export default App;
