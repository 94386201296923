import { db } from "services/firebase";

export const fetchProducts = () => {
  return async dispatch => {
    db.collection("products")
      .get()
      .then(querySnapshot => {
        const data = Object.assign(
          {},
          ...querySnapshot.docs.map(doc => {
            const { id } = doc;
            return { [id]: doc.data() };
          })
        );
        localStorage.setItem("@0Soft:products", JSON.stringify(data));
        dispatch({
          type: "FETCH_PRODUCTS",
          payload: { data },
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getProduct = barcode => {
  return async (dispatch, getState) => {
    const barcodes = getState().products;
    return barcodes[barcode];
  };
};
