import React from "react";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";

const UserAvatar = ({ size = "lg" }) => {
  const user = useSelector(state => state.auth.user);

  if (user.photoURL) {
    return (
      <img
        alt="..."
        className={`rounded-circle ${size === "sm" ? "avatar avatar-sm" : ""}`}
        src={user.photoURL}
      />
    );
  }

  return (
    <Avatar
      className="avatar rounded-circle"
      name={user.displayName}
      email={user.email}
      unstyled={true}
      size={size === "sm" ? "36" : "180"}
    />
  );
};

export default UserAvatar;
