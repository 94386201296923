import { swal } from "react-redux-sweetalert";
import { auth, db } from "services/firebase";
import * as ipp from "instagram-profile-picture";
import { unSubscribeSales } from "../sales/actions";

export const updateProfile = (name, username, backgroundImage) => {
  return async (dispatch, getState) => {
    const user = auth.currentUser;
    const userData = { displayName: name };
    const extraData = { username, backgroundImage };
    if (!user.photoURL || username !== getState().auth.extra.username) {
      await ipp(username)
        .then(user => (userData.photoURL = user))
        .catch(() => (userData.photoURL = null));
    }

    user.updateProfile(userData).then(() => {
      db.collection("users")
        .doc(user.uid)
        .set(extraData, { merge: true })
        .then(() => {
          dispatch({
            type: "SET_EXTRA_USER",
            payload: { extra: extraData },
          });
          localStorage.setItem("@0Soft:user:extra", JSON.stringify(extraData));
        });

      dispatch({ type: "SET_USER", payload: { user } });
      localStorage.setItem("@0Soft:user", JSON.stringify(user));

      dispatch(swal(":) Oba", "Perfil atualizado com sucesso", "success"));
    });
  };
};

export const signIn = (email, password) => {
  return async dispatch => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              dispatch({
                type: "SET_EXTRA_USER",
                payload: { extra: doc.data() },
              });
              localStorage.setItem("@0Soft:user:extra", JSON.stringify(doc.data()));
            }
          });

        dispatch({ type: "SET_USER", payload: { user: user } });
        localStorage.setItem("@0Soft:user", JSON.stringify(user));
      })
      .catch(error => {
        console.log(error);
        dispatch(swal(":( Falha ao autenticar", "Email e/ou senha inválido", "error"));
      });
  };
};

export const signOut = () => {
  return dispatch => {
    auth.signOut().then(() => {
      localStorage.removeItem("@0Soft:user");
      localStorage.removeItem("@0Soft:user:extra");
      dispatch({ type: "SET_USER", payload: { user: null } });
      dispatch({ type: "SET_EXTRA_USER", payload: { extra: null } });
      dispatch(unSubscribeSales());
    });
  };
};
