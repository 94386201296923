import moment from "moment";
import { useSelector } from "react-redux";
import Utils from "lib/utils";
import { colors } from "variables/charts";

class dashboardDomain {
  getData = () => {
    const sales = useSelector(state => state.sales.data);

    let processed = {
      dataChartHourlyBilling: [],
      dataChartHourlySales: [],
      dataChartHourlyItems: [],
      dataTableLastSales: [],
      dataDoughnutPayments: [],
      dataDoughnutSellers: [],
    };

    let labels = [];
    let dataHourlyBilling = [];
    let dataHourlySales = [];
    let dataHourlyItems = [];
    let first = 0;
    let last = 0;

    [...Array(24).keys()].forEach(r => {
      labels.push(r + "h");
      dataHourlyBilling.push(0);
      dataHourlySales.push(0);
      dataHourlyItems.push(0);
    });

    sales.forEach(r => {
      let date = moment(r.createdAt);
      if (first === 0 || date.hour() < first) {
        first = date.hour();
      }
      if (last === 0 || date.hour() > last) {
        last = date.hour();
      }
      dataHourlyBilling[date.hour()] += r.amount;
      dataHourlySales[date.hour()] += 1;
      dataHourlyItems[date.hour()] += r.totalItems;
    });

    first = first !== 0 ? first - 1 : 0;
    last = last !== 0 ? last + 2 : 0;

    processed.dataChartHourlyBilling = {
      labels: labels.slice(first, last),
      datasets: [
        {
          label: "Horários",
          data: dataHourlyBilling.slice(first, last),
        },
      ],
    };

    processed.dataChartHourlySales = {
      labels: labels.slice(first, last),
      datasets: [
        {
          label: "Volume de Vendas",
          data: dataHourlySales.slice(first, last),
        },
      ],
    };

    processed.dataChartHourlyItems = {
      labels: labels.slice(first, last),
      datasets: [
        {
          label: "Volume de Itens Vendidos",
          data: dataHourlyItems.slice(first, last),
        },
      ],
    };

    let payment = [0, 0, 0];
    let sellers = [0, 0, 0];

    processed.dataTableLastSales = sales.map(r => {
      return [
        r.createdAt,
        moment(r.createdAt).fromNow(),
        r.payment,
        r.totalItems,
        r.exchange ? Utils.format.currency(r.exchange) : "-",
        Utils.format.currency(r.amount),
      ];
    });

    let paymentMap = {
      Dinheiro: 0,
      Crédito: 1,
      Débito: 2,
    };

    sales.forEach(r => {
      payment[paymentMap[r.payment]] = payment[paymentMap[r.payment]] + 1;
      r.items.forEach(s => {
        sellers[s.salesperson - 1] = sellers[s.salesperson - 1] + 1;
      });
    });
    let backgroundColor = [colors.theme.success, colors.theme.warning, colors.theme.primary];
    processed.dataDoughnutPayments = {
      labels: ["Dinheiro", "Crédito", "Débito"],
      datasets: [
        {
          label: "Métodos de Pagamento",
          data: payment,
          backgroundColor,
        },
      ],
    };

    processed.dataDoughnutSellers = {
      labels: ["1", "2", "3"],
      datasets: [
        {
          label: "Número de vendas por vendedor",
          data: sellers,
          backgroundColor,
        },
      ],
    };

    return processed;
  };

  getDataHeader = () => {
    const sales = useSelector(state => state.sales.data);

    let lessOnehour = moment().subtract(1, "hour");
    let lessTwohour = moment().subtract(2, "hour");

    let billingLastOneHour = 0;
    let billingLastTwoHour = 0;

    let quantityItemsSoldLastOneHour = 0;
    let quantityItemsSoldLastTwoHour = 0;

    let salesAmountLastOneHour = 0;
    let salesAmountLastTwoHour = 0;

    let processed = {
      billing: 0,
      quantityItemsSold: 0,
      salesAmount: 0,
      infBilling: 0,
      infSales: 0,
      infAverageTicket: 0,
      infItemsSales: 0,
    };

    processed.billing = sales.reduce((a, c) => {
      return a + c.amount;
    }, 0);

    processed.quantityItemsSold = sales.reduce((a, c) => {
      return a + c.totalItems;
    }, 0);

    processed.salesAmount = sales.length;

    sales.forEach(r => {
      let current = moment(r.createdAt);
      if (current.isBetween(lessOnehour, moment())) {
        billingLastOneHour += r.amount;
        quantityItemsSoldLastOneHour += r.totalItems;
        salesAmountLastOneHour++;
      }
      if (current.isBetween(lessTwohour, lessOnehour)) {
        billingLastTwoHour += r.amount;
        quantityItemsSoldLastTwoHour += r.totalItems;
        salesAmountLastTwoHour++;
      }
    });

    processed.infBilling = (100 * billingLastOneHour) / billingLastTwoHour - 100 || 0;
    processed.infSales = (100 * salesAmountLastOneHour) / salesAmountLastTwoHour - 100 || 0;
    processed.infItemsSales =
      (100 * quantityItemsSoldLastOneHour) / quantityItemsSoldLastTwoHour - 100 || 0;
    processed.infAverageTicket =
      (100 * (billingLastOneHour / salesAmountLastOneHour)) /
        (billingLastTwoHour / salesAmountLastTwoHour) -
        100 || 0;

    return processed;
  };
}

export default new dashboardDomain();
