import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import moment from "moment";
import { removeSale } from "store/modules/sales/actions";
import Utils from "lib/utils";

class salesDomain {
  getData = () => {
    const sales = useSelector(state => state.sales.data);
    const dispatch = useDispatch();

    let processed = {
      general: [],
      sellers: [],
      products: [],
    };

    processed.general.columns = [
      { name: "#" },
      {
        name: "Data venda",
        sort: true,
        defaultSort: true,
        sortInverse: true,
        customRender: v => moment(v).format("LLL"),
      },
      { name: "Método de pagamento", sort: true },
      { name: "Qtd. de Items" },
      { name: "Valor Total", customRender: v => Utils.format.currency(v) },
      { name: "Troco", customRender: v => (v ? Utils.format.currency(v) : "-") },
      {
        name: "",
        width: 25,
        align: "right",
        customRender: v => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu size="sm" className="dropdown-menu-arrow" right>
                <DropdownItem
                  href="#pablo"
                  color=""
                  onClick={e => {
                    e.preventDefault();
                    dispatch(removeSale(v.id));
                  }}
                >
                  <span className="d-flex align-items-center red">
                    <i className="fas fa-trash-alt" />
                    <span style={{ marginLeft: "10px" }}>Remover</span>
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
      { name: "Última inclusão", defaultSort: true, hidden: true, sortInverse: true },
    ];

    processed.general.data = sales.map(s => [
      s.id,
      s.createdAt,
      s.payment,
      s.totalItems,
      s.amount,
      s.exchange,
      s,
    ]);

    processed.general.options = {
      perPage: 7,
    };

    processed.sellers.columns = [
      {
        name: "Vendedor",
        sort: true,
        defaultSort: true,
      },
      { name: "Itens Vendidos", sort: true },
      {
        name: "Vendas em Crédito",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
      {
        name: "Vendas em Débito",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
      {
        name: "Vendas em Dinheiro",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
      {
        name: "Valor Total",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
    ];

    let mapSales = [...Array(3).keys()].map(r => {
      return {
        salesperson: r + 1,
        quantity: 0,
        amount: 0,
        credit: 0,
        debit: 0,
        money: 0,
      };
    });

    let mapProducts = [];

    sales.forEach(s => {
      s.items.forEach(i => {
        let id = i.salesperson - 1;
        let total = i.price * i.quantity;
        mapSales[id] = {
          salesperson: mapSales[id].salesperson,
          quantity: mapSales[id].quantity + i.quantity,
          amount: mapSales[id].amount + total,
          credit: mapSales[id].credit + (s.payment === "Crédito" ? total : 0),
          debit: mapSales[id].debit + (s.payment === "Débito" ? total : 0),
          money: mapSales[id].money + (s.payment === "Dinheiro" ? total : 0),
        };

        mapProducts[i.barcode] = {
          barcode: i.barcode,
          salesperson: i.salesperson,
          price: i.price,
          quantityAmount:
            ((mapProducts[i.barcode] && mapProducts[i.barcode].quantityAmount) || 0) + i.quantity,
          billing:
            ((mapProducts[i.barcode] && mapProducts[i.barcode].billing) || 0) +
            i.quantity * i.price,
        };
      });
    });

    processed.sellers.data = mapSales.map(r => {
      return [
        r.salesperson,
        r.quantity || 0,
        r.credit || 0,
        r.debit || 0,
        r.money || 0,
        r.amount || 0,
      ];
    });

    processed.sellers.options = {
      perPage: 3,
      showPagination: false,
    };

    processed.products.columns = [
      {
        name: "Barcode",
        sort: true,
        defaultSort: true,
      },
      { name: "Vendedor", sort: true },
      {
        name: "Valor Unitário",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
      {
        name: "Quantidade Vendida",
        sort: true,
      },
      {
        name: "Faturamento Total",
        sort: true,
        customRender: v => Utils.format.currency(v),
      },
    ];

    processed.products.data = Object.values(mapProducts).map(r => {
      return [r.barcode, r.salesperson, r.price, r.quantityAmount || 0, r.billing || 0];
    });

    processed.products.options = {
      perPage: 7,
    };

    return processed;
  };
}

export default new salesDomain();
