const INITIAL_STATE =  JSON.parse(localStorage.getItem("@0Soft:products")) || {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS":
      return action.payload.data;
    default:
      return state;
  }
};
