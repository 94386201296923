import { swal } from "react-redux-sweetalert";
import { db } from "services/firebase";

export const fetchSales = () => {
  return async (dispatch, getState) => {
    const loaded = getState().sales.loaded;

    if (!loaded) {
      dispatch({ type: "LOADING_SALES" });
      db.collection("sales")
        .get()
        .then(querySnapshot => {
          const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          dispatch({ type: "SET_SALES", payload: { data } });
        })
        .finally(() => {
          dispatch({ type: "SET_LOADED_SALES", payload: { loaded: true } });
          dispatch({ type: "FULLFILED_SALES" });
        });
    }
  };
};

export const subscribeSales = () => {
  return async (dispatch, getState) => {
    let listener = getState().sales.listener;

    if (!listener) {
      listener = db.collection("sales").onSnapshot(querySnapshot => {
        dispatch({ type: "LOADING_SALES" });
        const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        dispatch({ type: "SET_SALES", payload: { data } });
        dispatch({ type: "FULLFILED_SALES" });
      });

      dispatch({ type: "SET_LISTENER_SALES", payload: { listener } });
    }
  };
};

export const unSubscribeSales = () => {
  return async (dispatch, getState) => {
    const listener = getState().sales.listener;

    if (listener) {
      listener();
      dispatch({ type: "CLEAR_LISTENER_SALES" });
    }
  };
};

export const addSale = sale => {
  return async dispatch => {
    db.collection("sales")
      .add(sale)
      .then(() => {
        dispatch(swal("Ebaaa :)", "Venda finalizada com sucesso!", "success"));
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const removeSale = id => {
  return async dispatch => {
    await dispatch(
      swal({
        title: "Tem certeza?",
        text: "Deseja remover a venda?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5E72E4",
        cancelButtonText: "Sim, remover",
        confirmButtonText: "Manter",
        onCancel: async () => {
          db.collection("sales")
            .doc(id)
            .delete()
            .then(() => {
              dispatch({ type: "REMOVE_SALE", payload: { id } });
              dispatch(swal(":) beleza", "Venda foi removida com sucesso", "success"));
            })
            .catch(error => console.error(error));
        },
      })
    );
  };
};
