const INITIAL_STATE = {
  loading: false,
  loaded: false,
  data: [],
  listener: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SALES":
      return { ...state, data: action.payload.data };
    case "LOADING_SALES":
      return { ...state, loading: true };
    case "SET_LOADED_SALES":
      return { ...state, loaded: action.payload.loaded };
    case "FULLFILED_SALES":
      return { ...state, loading: false };
    case "SET_LISTENER_SALES":
      return { ...state, listener: action.payload.listener };
    case "CLEAR_LISTENER_SALES":
      return { ...state, listener: null };
    case "REMOVE_SALE":
      return { ...state, data: state.data.filter(s => s.id !== action.payload.id) };
    default:
      return state;
  }
};
