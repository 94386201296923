import Index from "views/Index.jsx";
import Profile from "views/Profile.jsx";
import Login from "views/Login.jsx";
import Sales from "views/Sales.jsx";
import IndexCart from "views/Checkout/Index.jsx";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    sidebar: true,
  },
  {
    path: "/user-profile",
    name: "Perfil do Usuário",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/vendas",
    name: "Vendas",
    icon: "ni ni-bullet-list-67 text-red",
    component: Sales,
    layout: "/admin",
    sidebar: true,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Ponto de Venda",
    icon: "ni ni-cart text-info",
    component: IndexCart,
    layout: "/checkout",
    sidebar: true,
  },
];
export default routes;
