import React from "react";
import { Navbar, Container, Button } from "reactstrap";
import { Link } from "react-router-dom";

const CheckoutNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid className="d-flex justify-content-between w-100">
          <img src={require("assets/img/brand/logo-white.svg")} height="40" alt="ZeroSoft" />
          <Link to="/">
            <Button outline color="white" className="exit-btn outline-white mr-0">
              <i className="fa fa-share-square" /> Sair
            </Button>
          </Link>
        </Container>
      </Navbar>
    </>
  );
};

export default CheckoutNavbar;
