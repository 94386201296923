import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Chart from "chart.js";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { fetchSales, subscribeSales } from "store/modules/sales/actions";
import {
  chartOptions,
  parseOptions,
  chartHourlyBilling,
  chartHourlySales,
  doughnut,
} from "variables/charts.jsx";
import dashboardDomain from "../domain/dashboard";
import Datatable from "components/Datatable";

import Header from "components/Headers/Header.jsx";

const Index = () => {
  const [typeDoughnut, setTypeDoughnut] = useState(1);
  const [typeLine, setTypeLine] = useState(1);
  const dispatch = useDispatch();
  const sales = useSelector(state => state.sales);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    dispatch(fetchSales());
  }, [dispatch]);

  useEffect(() => {
    if (sales.loaded) {
      dispatch(subscribeSales());
    }
  }, [dispatch, sales.loaded]);

  const {
    dataChartHourlyBilling,
    dataChartHourlySales,
    dataChartHourlyItems,
    dataTableLastSales,
    dataDoughnutPayments,
    dataDoughnutSellers,
  } = dashboardDomain.getData();

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">Visão Geral</h6>
                    <h2 className="text-white mb-0">Faturamento por Hora</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={dataChartHourlyBilling}
                    options={chartHourlyBilling.options}
                    getDatasetAtEvent={e => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">Volume</h6>
                    <h2 className="mb-0">{typeLine === 1 ? "Vendas" : "Itens Vendidos"}</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeLine === 1,
                          })}
                          href="#pagamentos"
                          onClick={e => {
                            e.preventDefault();
                            setTypeLine(1);
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-shopping-cart" />
                          </span>
                          <span className="d-md-none">P</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeLine === 2,
                          })}
                          data-toggle="tab"
                          href="#vendedores"
                          onClick={e => {
                            e.preventDefault();
                            setTypeLine(2);
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-boxes" />
                          </span>
                          <span className="d-md-none">V</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={typeLine === 1 ? dataChartHourlySales : dataChartHourlyItems}
                    options={chartHourlySales.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Datatable
              title={
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Últimas Vendas</h3>
                  </div>
                  <div className="col text-right">
                    <Link to="/admin/vendas">
                      <Button color="primary" size="sm">
                        Ver Todas as Vendas
                      </Button>
                    </Link>
                  </div>
                </Row>
              }
              className="shadow h-100"
              columns={[
                {
                  name: "Horário",
                  defaultSort: true,
                  sortInverse: true,
                  hidden: true,
                },
                {
                  name: "Horário",
                },
                {
                  name: "Método de Pagamento",
                },
                {
                  name: "Qtd. de Itens",
                },
                {
                  name: "Troco",
                },
                {
                  name: "Valor",
                },
              ]}
              data={dataTableLastSales}
              options={{ perPage: 7, showPagination: false }}
            />
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">Ranking</h6>
                    <h2 className="mb-0">
                      {typeDoughnut === 1 ? "Tipos de Pagamento" : "Vendedores"}
                    </h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeDoughnut === 1,
                          })}
                          href="#pagamentos"
                          onClick={e => {
                            e.preventDefault();
                            setTypeDoughnut(1);
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-money-bill-wave" />
                          </span>
                          <span className="d-md-none">P</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeDoughnut === 2,
                          })}
                          data-toggle="tab"
                          href="#vendedores"
                          onClick={e => {
                            e.preventDefault();
                            setTypeDoughnut(2);
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-user-tie" />
                          </span>
                          <span className="d-md-none">V</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Doughnut
                    data={typeDoughnut === 1 ? dataDoughnutPayments : dataDoughnutSellers}
                    options={doughnut.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
