import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import rootReducer from "./modules/rootReducer";

const loggerMiddleware = createLogger();
const middlewares = [thunkMiddleware];
let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
  middlewares.push(loggerMiddleware);
}

const store = createStore(rootReducer,
  composeEnhancers(applyMiddleware(...middlewares)));

export default store;