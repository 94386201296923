import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { updateProfile } from "store/modules/auth/actions";

import UserHeader from "components/Headers/UserHeader";
import UserAvatar from "components/UserAvatar";

const Profile = () => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.user) {
      setName(auth.user.displayName || "");
      setEmail(auth.user.email);
    }

    if (auth.extra) {
      setUsername(auth.extra.username || "");
      setBackgroundImage(auth.extra.backgroundImage || "");
    }
  }, [auth]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateProfile(name, username, backgroundImage));
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <UserAvatar />
                  </div>
                </Col>
              </Row>
              <CardHeader className="border-0 pt-8 pt-md-4 pb-0 pb-md-4" />
              <CardBody className="pt-0 pt-md-4 mt-md-6">
                <div className="text-center">
                  <h3>{auth.user.displayName ? auth.user.displayName : auth.user.email}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Administrador(a)
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">Informação do usuário</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-name">
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-name"
                            placeholder="Nome"
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Usuário do Instagram
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-at" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="form-control form-control-alternative"
                              id="input-username"
                              placeholder="usuario"
                              type="text"
                              autoFocus
                              value={username}
                              onChange={e => setUsername(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Endereço de Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="usuario@email.com"
                            disabled
                            type="email"
                            value={email}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Imagem de fundo
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="http://url-imagem"
                            type="url"
                            value={backgroundImage}
                            onChange={e => setBackgroundImage(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button type="submit" color="success" block>
                      Salvar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
