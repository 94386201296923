import React from "react";
import moment from "moment";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer align-items-center justify-content-between flex-sm-wrap">
        <div className="copyright text-center text-xl-left text-muted">
          Developed with ♥ by{" "}
          <a
            className="font-weight-bold ml-1"
            href="https://zerosoft.com.br/?ref=zero-bazar"
            rel="noopener noreferrer"
            target="_blank"
          >
            ZeroSoft
          </a>
        </div>
        <div className="copyright text-center text-xl-right text-muted">
          {`© ${moment().format("YYYY")} `}
          <a
            className="font-weight-bold ml-1"
            href="https://www.creative-tim.com?ref=adr-admin-footer"
            rel="noopener noreferrer"
            target="_blank"
          >
            Creative Tim
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
