import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardFooter,
  Modal,
  Spinner,
} from "reactstrap";
import Datatable from "components/Datatable";
import { fetchProducts } from "store/modules/products/actions";
import {
  addItemCart,
  removeItemCart,
  incItemCart,
  decItemCart,
  clearCart,
  endCart,
} from "store/modules/cart/actions";
import Utils from "lib/utils";

export const Cart = () => {
  const [barcode, setBarcode] = useState("");
  const [payment, setPayment] = useState("");
  const [endSaleMoney, setEndSaleMoney] = useState(false);
  const [data, setData] = useState([]);
  const [amountReceived, setAmoutReceived] = useState("");
  const cart = useSelector(state => state.cart.current);
  const loading = useSelector(state => state.cart.loading);
  const dispatch = useDispatch();
  const barcodeInput = useRef(null);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    const tmpData = cart.map(c => [c.barcode, c.price, c.salesperson, c, c, c.updateAt]);
    setData(tmpData);
    barcodeInput.current.focus();
  }, [cart]);

  const quantity = useMemo(() => {
    return cart.reduce((acc, c) => (acc += c.quantity || 0), 0);
  }, [cart]);

  const amount = useMemo(() => {
    return cart.reduce((acc, c) => (acc += c.price * c.quantity), 0);
  }, [cart]);

  const exchange = useMemo(() => {
    if (payment === "Dinheiro") {
      return amountReceived > amount ? amountReceived - amount : 0;
    }
    return undefined;
  }, [amount, amountReceived, payment]);

  const handleChange = e => {
    if (/^\d*$/.test(e.target.value)) {
      setBarcode(e.target.value);
    }
  };

  const handleChageAmountReceived = e => {
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      setAmoutReceived(e.target.value);
    }
  };

  const handleEndSale = useCallback(() => {
    if (payment === "Dinheiro" && !amountReceived) {
      setEndSaleMoney(true);
    } else {
      dispatch(endCart(payment, exchange));
      setBarcode("");
      setPayment("");
    }
  }, [amountReceived, dispatch, exchange, payment]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(addItemCart(barcode));
      setBarcode("");
      barcodeInput.current.focus();
    },
    [barcode, dispatch]
  );

  const incItem = useCallback(
    barcode => {
      dispatch(incItemCart(barcode));
    },
    [dispatch]
  );

  const decItem = useCallback(
    barcode => {
      dispatch(decItemCart(barcode));
    },
    [dispatch]
  );

  const clearCartInternal = useCallback(() => {
    dispatch(clearCart());
    setBarcode("");
    setPayment("");
  }, [dispatch]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pb-md-5 pt-5 pt-lg-8 pt-md-8" />
      {/* <div className="header bg-gradient-info pb-5 pt-5 pt-md-8" /> */}
      <Container className="mt--7" fluid>
        <Row className="full">
          <Col className="mb-5 mb-xl-0">
            <Datatable
              title={
                <form onSubmit={handleSubmit}>
                  <Row className="d-flex align-items-center">
                    <Col md={9}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control form-control-alternative"
                          placeholder="Digite o código do produto"
                          type="text"
                          autoFocus
                          onChange={handleChange}
                          value={barcode}
                          required
                          maxLength={13}
                          ref={barcodeInput}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={3}>
                      <Button color="primary btn-add" block>
                        <i className="fa fa-plus" /> Adicionar
                      </Button>
                    </Col>
                  </Row>
                </form>
              }
              className="shadow h-100"
              columns={[
                { name: "Código" },
                {
                  name: "R$ Unitário",
                  customRender: v => Utils.format.currency(v),
                },
                { name: "Vendedor" },
                {
                  name: "Quantidade",
                  width: 200,
                  className: "col-quantity",
                  customRender: v => {
                    return (
                      <div className="d-flex justify-content-between">
                        <Button
                          color="danger"
                          size="sm"
                          className="mr-0"
                          onClick={() => decItem(v.barcode)}
                        >
                          <i className="fa fa-minus" />
                        </Button>
                        <Input
                          className="text-center input-quantity"
                          type="text"
                          bsSize="sm"
                          readOnly
                          value={v.quantity}
                        />
                        <Button color="success" size="sm" onClick={() => incItem(v.barcode)}>
                          <i className="fa fa-plus" />
                        </Button>
                      </div>
                    );
                  },
                },
                {
                  name: "",
                  width: 25,
                  align: "right",
                  customRender: v => {
                    return (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu size="sm" className="dropdown-menu-arrow" right>
                          <DropdownItem
                            href="#pablo"
                            color=""
                            onClick={e => {
                              e.preventDefault();
                              dispatch(removeItemCart(v.barcode));
                            }}
                          >
                            <span className="d-flex align-items-center red">
                              <i className="fas fa-trash-alt" />
                              <span style={{ marginLeft: "10px" }}>Remover</span>
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    );
                  },
                },
                { name: "Última inclusão", defaultSort: true, hidden: true, sortInverse: true },
              ]}
              data={data}
              options={{ perPage: 6 }}
              noDataFound="Nenhum item incluído no carrinho"
            />
          </Col>
          <Col xl="4">
            <Card className="shadow h-100">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="ls-1 mb-1">Quantidade Total</h5>
                    <h1 className="mb-0 float-right">{`${quantity} ${
                      quantity === 1 ? "item" : "itens"
                    }`}</h1>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <h5 className="ls-1 mb-4">Forma de pagamento</h5>
                <div>
                  {["Dinheiro", "Crédito", "Débito"].map(b => (
                    <Button
                      key={b}
                      block
                      style={{ marginBottom: "25px" }}
                      color="primary"
                      className="mb-20 mr-0"
                      outline={payment !== b}
                      onClick={() => setPayment(b)}
                    >
                      {b}
                    </Button>
                  ))}
                </div>
                <div>
                  <div className="dropdown-divider" />
                  <h5 className="ls-1 mb-4 mt-4">Total</h5>
                  <h1 className="mb-0 float-right">{Utils.format.currency(amount)}</h1>
                </div>
              </CardBody>
              <CardFooter className="d-flex py-4 w-100 border-0">
                <Button
                  color="danger"
                  outline
                  disabled={cart.length <= 0}
                  onClick={clearCartInternal}
                  title="Cancelar venda"
                >
                  <i className="fa fa-times" />
                </Button>
                <Button
                  className="w-100"
                  color="success"
                  disabled={payment === "" || cart.length <= 0 || loading}
                  onClick={handleEndSale}
                >
                  {loading ? <Spinner size="sm" color="primary" /> : <i className="fa fa-check" />}{" "}
                  Finalizar
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={endSaleMoney}
        toggle={() => setEndSaleMoney(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="endSale">
            Finalizar venda
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setEndSaleMoney(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <InputGroup>
              <InputGroupAddon addonType="prepend">R$</InputGroupAddon>
              <Input
                className="form-control text-right"
                placeholder="Digite o valor recebido"
                type="text"
                onChange={handleChageAmountReceived}
                value={amountReceived}
              />
              <InputGroupAddon addonType="append">,00</InputGroupAddon>
            </InputGroup>
            <hr />
            <h4 className="d-flex justify-content-between">
              <span>Valor da compra:</span>
              <span>{Utils.format.currency(amount)}</span>
            </h4>
            <hr />
            <h2 className="d-flex justify-content-between">
              <span>Troco:</span>
              <span>{Utils.format.currency(exchange)}</span>
            </h2>
            <hr />
            <Button
              disabled={!Boolean(amountReceived) || amountReceived < amount}
              color="success"
              type="submit"
              onClick={() => {
                setEndSaleMoney(false);
                handleEndSale();
              }}
              block
            >
              Finalizar
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Cart;
