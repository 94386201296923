import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { fetchSales, subscribeSales } from "store/modules/sales/actions";
import Header from "components/Headers/Header.jsx";
import Datatable from "components/Datatable";
import classnames from "classnames";
import salesDomain from "../domain/sales";

const Sales = () => {
  const sales = useSelector(state => state.sales);
  const dispatch = useDispatch();
  const [typeTable, setTypeTable] = useState("general");

  useEffect(() => {
    dispatch(fetchSales());
  }, [dispatch]);

  useEffect(() => {
    if (sales.loaded) {
      dispatch(subscribeSales());
    }
  }, [dispatch, sales.loaded]);

  const dataSales = salesDomain.getData();

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Datatable
              title={
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Vendas</h3>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeTable === "general",
                          })}
                          onClick={e => {
                            e.preventDefault();
                            setTypeTable("general");
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-money-bill-wave" /> Geral
                          </span>
                          <span className="d-md-none">G</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeTable === "sellers",
                          })}
                          data-toggle="tab"
                          onClick={e => {
                            e.preventDefault();
                            setTypeTable("sellers");
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fas fa-user-tie mr-10" /> Vendedores
                          </span>
                          <span className="d-md-none">V</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: typeTable === "products",
                          })}
                          data-toggle="tab"
                          onClick={e => {
                            e.preventDefault();
                            setTypeTable("products");
                          }}
                        >
                          <span className="d-none d-md-block">
                            <i className="fa fa-th-large mr-10" /> Produtos
                          </span>
                          <span className="d-md-none">P</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              }
              className="shadow h-100"
              columns={dataSales[typeTable].columns}
              data={dataSales[typeTable].data}
              options={dataSales[typeTable].options}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sales;
